import React from 'react'
import { CardSingleItem, PageSection } from '../components'
import { toursData } from '../@utils/data'
import { useTranslation } from 'react-i18next'

const Tours = () => {
  const { t, i18n } = useTranslation()
  let langTours = undefined

  switch (i18n.language) {
    case 'en':
      langTours = toursData.en
      break
    case 'pt-PT':
      langTours = toursData.pt
      break
  }

  return (
    <>
      <PageSection className="">
        <PageSection.Eyebrow>
          {t('tours.eyebrow')}
        </PageSection.Eyebrow>
        <PageSection.Heading>
          {t('tours.title')}
        </PageSection.Heading>
        <PageSection.Description>
          {t('tours.description')}
        </PageSection.Description>
        <PageSection.Content className='columns-1 gap-6 space-y-6 md:columns-2 xl:columns-3'>
          {langTours.map( (tour, i) => {
            return (
              <div className={`w-full relative`}>
                <CardSingleItem key={tour.id} className={`w-full`} thumbnail={tour.thumbnail} thumbnailAlt={tour.thumbnailAlt}>
                  <CardSingleItem.Header>
                    <img src={require(`./../assets/img/${tour.thumbnail}`)} alt={tour.thumbnailAlt} />
                  </CardSingleItem.Header>
                  <CardSingleItem.Body>
                    <CardSingleItem.Heading>
                      <h3>{tour.title}</h3>
                    </CardSingleItem.Heading>
                    <CardSingleItem.Description>
                      <CardSingleItem.PriceInfo estDuration={tour.duration} startingFrom={t('general.startingFrom', { cost: tour.startingFrom })} />
                    </CardSingleItem.Description>
                    <CardSingleItem.Action to={`/tours/${tour.slug}`}>
                      <span>{t('buttons.bookThisTour')}</span>
                    </CardSingleItem.Action>
                  </CardSingleItem.Body>
                </CardSingleItem>
              </div>

            )
          } )}
        </PageSection.Content>
      </PageSection>
    </>
  )
}

export default Tours
