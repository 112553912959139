import React from 'react'

const Container = ({as='div', children, className}) => {
  const DynamicTag = `${as}`
  return (
    <>
      <DynamicTag className={`container ${className ? className : ''}`}>
        {children}
      </DynamicTag>
    </>
  )
}

export default Container
