import React from 'react'
import { CardSingleItem, PageSection } from '../components'
import { transfersData } from '../@utils/data'
import { Trans, useTranslation } from 'react-i18next'

const Transfers = () => {
  const { t, i18n } = useTranslation()
  let langTransfers = undefined

  switch (i18n.language) {
    case 'en':
      langTransfers = transfersData.en
      break
    case 'pt-PT':
      langTransfers = transfersData.pt
      break
  }

  return (
    <PageSection className="">
      <PageSection.Eyebrow>
        {t('transfers.eyebrow')}
      </PageSection.Eyebrow>
      <PageSection.Heading>
        {t('transfers.title')}
      </PageSection.Heading>
      <PageSection.Description>
        {t("transfers.description")}
      </PageSection.Description>
      <PageSection.Content className='columns-1 gap-6 space-y-6 md:columns-2 xl:columns-3'>
        {langTransfers.map( (transfer, i) => {
          return (
            <div className={`w-full relative`}>
              <CardSingleItem key={transfer.id} className={`w-full`} thumbnail={transfer.thumbnail} thumbnailAlt={transfer.thumbnailAlt}>
                <CardSingleItem.Header>
                  <img src={require(`./../assets/img/${transfer.thumbnail}`)} alt={transfer.thumbnailAlt} />
                </CardSingleItem.Header>
                <CardSingleItem.Body>
                  <CardSingleItem.Heading>
                    <h3>{transfer.title}</h3>
                  </CardSingleItem.Heading>
                  <CardSingleItem.Description>
                    <CardSingleItem.PriceInfo startingFrom={transfer.startingFrom} />
                  </CardSingleItem.Description>
                  <CardSingleItem.Action to={`/transfers/${transfer.slug}`}>
                    <span>{t('buttons.bookThisTour')}</span>
                  </CardSingleItem.Action>
                </CardSingleItem.Body>
              </CardSingleItem>
            </div>

          )
        } )}
      </PageSection.Content>
    </PageSection>
  )
}

export default Transfers
