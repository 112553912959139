import { React } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';


const Header = ({ children }) => (<div className='card-single-item__header'>{children}</div>)

const Body = ({ children }) => (<div className='card-single-item__content'>{children}</div>)

const Heading = ({ children }) => (<div className='card-single-item__heading'>{children}</div>)

const Description = ({ children }) => (<div className='card-single-item__description'>{children}</div>)

const PriceInfo = ({ estDuration, startingFrom }) => {
  const { t, i18n } = useTranslation()

  return (
    <div className="flex gap-x-6">
      {
        startingFrom ?
          (
            <div className="flex flex-col">
              <p className="text-light-grey">{ t('general.startingFromLabel')}</p>
              <p className="font-bold text-xl text-orange">{startingFrom}</p>
            </div>
          ) :
          ''
      }
      {
        estDuration ?
          (
            <div className="flex flex-col">
              <p className="text-light-grey">{ t('general.estDuration')}</p>
              <p className="text-xl text-dark-blue">{estDuration}</p>
            </div>
          ) :
          ''
      }
    </div>
  )
}

const Action = ({ children, className, to = '#' }) => (<Link className='card-single-item__action' to={to}>{children}</Link>)

const CardSingleItem = ({ children, className, thumbnail, thumbnailAlt }) => {
  return (
    <div className={`card-single-item${className ? ` ${className}` : ''}`}>
      {children}
    </div>
  )
}

CardSingleItem.Header = Header;
CardSingleItem.Body = Body;
CardSingleItem.Heading = Heading;
CardSingleItem.Description = Description;
CardSingleItem.PriceInfo = PriceInfo;
CardSingleItem.Action = Action;

export default CardSingleItem;
