import { React, useState } from 'react'
import emailjs from '@emailjs/browser'
import { useFormik, getIn } from 'formik'
import * as Yup from 'yup'
import PhoneInput from 'react-phone-input-2'
import { PHONE_COUNTRIES } from '../@utils/constants'

import 'react-phone-input-2/lib/style.css'

const FormikPhoneInput = (props) => {
  const {
    className,
    id,
    field: { name, value },
    form: {
      errors, handleBlur, setFieldValue, touched,
    },
    form,
    label,
    country,
    containerClass,
    onChange,
    disabled,
  } = props;

  const [isFocused, setFocused] = useState(false);
  const isError = getIn(touched, name) && getIn(errors, name);
  const errorStyle = isError ? 'error' : '';
  const filledStyle = (isFocused || value) ? 'filled' : '';
  const disabledStyle = disabled ? 'disabled' : '';

  const handleInputBlur = (e) => {
    setFocused(false);
    handleBlur(e);
  };

  const handleInputFocus = () => setFocused(true);

  const onValueChange = (phoneNumber) => {
    setFieldValue(name, phoneNumber);

    if (onChange !== null) {
      onChange(phoneNumber);
    }
  };

  return (
    <PhoneInput
      placeholder="Enter phone number"
      inputProps={{
        id: id,
        name: name
      }}
      value={value}
      onChange={onValueChange}
      country={country}
      containerClass={containerClass}
    />
  );
};


FormikPhoneInput.defaultProps = {
  className: '',
  id: 'phone',
  field: { name: 'phone', type: 'text' },
  label: '',
  onChange: null,
  country: 'pt',
  disabled: false,
};

const GeneralForm = ({ className }) => {
  const [checkCountry, setCheckCountry] = useState('pt')
  const [formatedPhone, setFormatedPhone] = useState('')

  const phoneRegEx = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;

  const formik = useFormik({
    initialValues: {
      fullName: '',
      email: '',
      phone: '',
      subject: '',
      message: '',
      terms: ''
    },
    validationSchema: Yup.object({
      fullName: Yup.string().required('Name is required'),
      email: Yup.string().email('Email address is not valid').required('Email is required'),
      phone: Yup.string()
        .test('len', 'Phone number is not valid', (value) => {
          const correspondingCountry = PHONE_COUNTRIES.find((country) => country.iso2 === checkCountry.toUpperCase())
          let valid = false

          console.log(formatedPhone)
          console.log(value)

          if (correspondingCountry && formatedPhone) {
            valid = new RegExp(correspondingCountry.validation).test(formatedPhone?.replace(/ /g, ''))
          } else if (formatedPhone) {
            valid = /(([+][(]?[0-9]{1,3}[)]?)|([(]?[0-9]{4}[)]?))\s*[)]?[-\s.]?[(]?[0-9]{1,3}[)]?([-\s.]?[0-9]{3})([-\s.]?[0-9]{3,4})/.test(formatedPhone?.replace(/ /g, ''))
          } else if (formatedPhone === '') {
            valid = true
          }

          return valid
      }),
      subject: Yup.string().required('Subject is required'),
      message: Yup.string().required('Message is required')
    }),
    onSubmit: (values) => {
      const mail_configs = {
        from_name: values.fullName,
        reply_to: values.email,
        subject: values.subject,
        message: values.message,
        phone_number: values.phone
      }

      emailjs.send('service_driver', 'template_6lo0cby', mail_configs, 'xUp0VSUkSBd0zP3Pv')
        .then((response) => {
          console.log(response)
        }, (err) => {
          console.log(err);
        })

      console.log(mail_configs)
    }
  })

  const sendEmail = (e) => {
    e.preventDefault()

    const mail_configs = {
      from_name: formik.values.fullName,
      reply_to: formik.values.email,
      subject: formik.values.subject,
      message: formik.values.message
      (formik.values.phone && { phone_number: formik.value.phone })
    }

    emailjs.send('service_driver', 'template_rht7lmq', mail_configs, 'xUp0VSUkSBd0zP3Pv')
      .then((response) => {
        console.log(response)
      }, (err) => {
        console.log(err);
      })
  }

  return (
    <form className={`form form--bordered${ className ? ` ${className} ` : ' ' }isolate w-full flex`} onSubmit={formik.handleSubmit}>
      <div className="form-content shadow-sm">
        <div className="form-group">
          <div className="form__element w-full sm:w-6/12">
            <label htmlFor="fullName" className="form__label">
              Full Name
              {formik.touched.fullName && formik.errors.fullName ? (<span className="form__error">{formik.errors.fullName}</span>) : ''}
            </label>
            <input
              className="form__input"
              type="text"
              name="fullName"
              id="fullName"
              placeholder="Jane Smith"
              value={formik.values.fullName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <span className="form__focus"></span>
          </div>
          <div className="form__element">
            <label htmlFor="email" className="form__label">
              Email
              {formik.touched.email && formik.errors.email ? (<span className="form__error">{formik.errors.email}</span>) : ''}
            </label>
            <input
              type="text"
              name="email"
              id="email"
              className="form__input"
              placeholder="you@example.com"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <span className="form__focus"></span>
          </div>
        </div>
        <div className="form-group">
          <div className="form__element w-6/12">
            <label htmlFor="phone" className="form__label">
              Phone
              {formik.touched.phone && formik.errors.phone ? (<span className="form__error">{formik.errors.phone}</span>) : ''}
            </label>
            {/* <input
              type="text"
              name="phone"
              id="phone"
              className="form__input"
              placeholder="+351934012734"
              value={formik.values.phone}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            /> */}
            <FormikPhoneInput
              field={{
                name: 'phone',
                value: formik.values.phone
              }}
              id='phone'
              type='text'
              form={formik}
              containerClass="form__input"
              country={'pt'}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <span className="form__focus"></span>
          </div>
          <div className="form__element w-6/12">
            <label htmlFor="subject" className="form__label">
              Subject
              {formik.touched.subject && formik.errors.subject ? (<span className="form__error">{formik.errors.subject}</span>) : ''}
            </label>
            <input
              type="text"
              name="subject"
              id="subject"
              className="form__input"
              placeholder="Your subject here..."
              value={formik.values.subject}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <span className="form__focus"></span>
          </div>
        </div>
        <div className="form-group">
          <div className="form__element w-full">
            <label htmlFor="message" className="form__label">
              Message
              {formik.touched.message && formik.errors.message ? (<span className="form__error">{formik.errors.message}</span>) : ''}
            </label>
            <textarea
              name="message"
              className='form__input'
              id="message"
              rows="8"
              placeholder="Your message here..."
              value={formik.values.message}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            ></textarea>
            <span className="form__focus"></span>
          </div>
        </div>
      </div>
      <div className="form-consent">
        <p>By clicking "Give it wings!", you hereby consent to our Privacy Policy and agree to its terms.</p>
      </div>
      <div className="form-actions">
        <button className="btn" type="submit">Give it wings!</button>
      </div>
    </form>
  )
}

export default GeneralForm


/*
  const server = Express()
  const port = 5000

  const sendEmail = (fromEmail, fromName, recipientEmail, phoneNumber, subject, message) => {
    return new Promise((resolve, reject) => {
      let transporter = NodeMailer.createTransport({
        service: 'gmail',
        auth: {
          user: '',
          pass: ''
        }
      })

      const mail_configs = {
        from: fromEmail,
        to: recipientEmail,
        subject: subject,
        text: ```
          Nome: ${fromName}\n
          Número de telefone: ${phoneNumber}\n
          \n
          ${message}
        ```
      }

      transporter.sendMail(mail_configs, (error, info) => {
        if (error) {
          console.log(error)
          return reject({
            message: 'An error has occured'
          })
        }

        return resolve({ message: 'Email sent successfully' })
      })
    })
  }

  server.get("/", (req, res) => {
    sendEmail()
      .then((response) => res.send(response.message))
      .catch((error) => res.status(500).send(error.message))
  })

  server.listen(port, () => {
    console.log(`Nodemailer is listening at http://localhost:${port}`)
  })

  const validateSchema = (countryCode: string) => Yup.object({
  civility: Yup.string().required('Obligatoire'),
  phoneNumber: Yup.string()
    .required('Obligatoire')
    .test('len', 'Format de numéro de téléphone incorrect', (val) => {
      const correspondingCountry = PHONE_COUNTRIES.find((country) => country.iso2 === checkCountry.toUpperCase())

      let valide = false

      if (correspondingCountry && val) {
        valide = new RegExp(correspondingCountry.validation).test(val?.replace(/ /g, ''))
      } else if (val) {
        valide = /(([+][(]?[0-9]{1,3}[)]?)|([(]?[0-9]{4}[)]?))\s*[)]?[-\s.]?[(]?[0-9]{1,3}[)]?([-\s.]?[0-9]{3})([-\s.]?[0-9]{3,4})/.test(val?.replace(/ /g, ''))
      }

      return valide
  }),
}) */
