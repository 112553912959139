import React from "react";
import { useParams } from "react-router";
import { toursData } from "../@utils/data";
import { BookTourForm, PageSection } from "../components";
import { useTranslation } from "react-i18next";

const ToursDetails = () => {
  const { slug } = useParams();
  const { t, i18n } = useTranslation();

  let langTours = undefined;

  switch (i18n.language) {
    case "en":
      langTours = toursData.en;
      break;
    case "pt-PT":
      langTours = toursData.pt;
      break;
  }

  let tour = langTours.find((t) => t.slug === slug);
  console.log(tour);

  return (
    <>
      <PageSection>
        <PageSection.Content>
          <div className="tours-details">
            <div className="tours-details__content">
              <div className="tours-details__thumbnail">
                <img
                  src={require(`./../assets/img/${tour.thumbnail}`)}
                  alt={tour.thumbnailAlt}
                />
              </div>
              <div className="tours-details__title">
                <h1>{tour.title}</h1>
              </div>
              <div className="tours-details__sections">
                <section className="tours-details__section">
                  <div className="tours-details__title">
                    <h2>{t("toursDetails.about")}</h2>
                  </div>
                  <div className="tours-details__content">
                    <p>{tour.description}</p>
                  </div>
                </section>
                <section className="tours-details__section">
                  <div className="tours-details__title">
                    <h2>{t("toursDetails.totalCostOfTour")}</h2>
                  </div>
                  <div className="tours-details__content">
                    <ul>
                      <li>
                        {t("toursDetails.oneToFourPax", {
                          cost: tour.costs.oneToFour,
                        })}
                      </li>
                      <li>
                        {t("toursDetails.fivePlusPax", {
                          cost: tour.costs.fivePlus,
                        })}
                      </li>
                    </ul>
                  </div>
                </section>
                <section className="tours-details__section">
                  <div className="tours-details__title">
                    <h2>{t("toursDetails.duration")}</h2>
                  </div>
                  <div className="tours-details__content">
                    <p>{tour.duration}</p>
                  </div>
                </section>
                <section className="tours-details__section">
                  <div className="tours-details__title">
                    <h2>{t("toursDetails.keyDetails")}</h2>
                  </div>
                  <div className="tours-details__content">
                    <ul>
                      {tour.keyDetails.map((kD, i) => {
                        return <li key={i}>{kD}</li>;
                      })}
                    </ul>
                  </div>
                </section>
                <section className="tours-details__section">
                  <div className="tours-details__title">
                    <h2>{t("toursDetails.included")}</h2>
                  </div>
                  <div className="tours-details__content">
                    <ul>
                      {tour.included.map((includes, i) => {
                        return <li key={i}>{includes}</li>;
                      })}
                    </ul>
                  </div>
                </section>
                <section className="tours-details__section">
                  <div className="tours-details__title">
                    <h2>{t("toursDetails.notIncluded")}</h2>
                  </div>
                  <div className="tours-details__content">
                    <ul>
                      {tour.notIncluded.map((notIncludes, i) => {
                        return <li key={i}>{notIncludes}</li>;
                      })}
                    </ul>
                  </div>
                </section>
                <section className="tours-details__section">
                  <div className="tours-details__title">
                    <h2>{t("toursDetails.optional")}</h2>
                  </div>
                  <div className="tours-details__content">
                    <ul>
                      {tour.optional.map((option, i) => {
                        return <li key={i}>{option}</li>;
                      })}
                    </ul>
                  </div>
                </section>
              </div>
            </div>
            <div className="tours-details__form">
              <div className="tours-details__title">
                <h2>{t("toursDetails.bookNow")}</h2>
              </div>
              <BookTourForm
                tourName={tour.title}
                tourId={tour.id}
              ></BookTourForm>
            </div>
          </div>
        </PageSection.Content>
      </PageSection>
    </>
  );
};

export default ToursDetails;
