import React from 'react'

const Image = ({ children }) => {
  return (
    <div className="card-background__image">
      {children}
    </div>
  )
}

const Eyebrow = ({ children }) => {
  return (
    <div className='card-background__eyebrow'>
      {children}
    </div>
  )
}

const Heading = ({ as='h2', children }) => {
  const DynamicTag = `${as}`

  return (
    <DynamicTag className='card-background__heading'>
      {children}
    </DynamicTag>
  )
}

const Action = ({ children }) => {
  return (
    <div className='card-background__action'>
      {children}
    </div>
  )
}


const CardBackground = ({as='div', children, className}) => {
  const DynamicTag = `${as}`

  return (
    <DynamicTag className={`card-background ${className ? className : ''}`}>
      {children}
    </DynamicTag>
  )
}

CardBackground.Image = Image
CardBackground.Eyebrow = Eyebrow
CardBackground.Heading = Heading
CardBackground.Action = Action

export default CardBackground
