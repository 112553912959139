import {  Fragment, React, useEffect, useRef, useState} from 'react'
import { Link, NavLink } from 'react-router-dom'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { gsap, ScrollTrigger } from 'gsap/all'
import { SelectMenu } from './../components/'
import { useTranslation } from 'react-i18next'
import SVG from 'react-inlinesvg';

// register gsap plugins
gsap.registerPlugin(ScrollTrigger)

const user = {
  name: 'Tom Cook',
  email: 'tom@example.com',
  imageUrl:
    'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
}

const navigation = [
  { id: 0, name: 'Home', namePt: 'Home', current: true, path: '/' },
  { id: 1, name: 'Tours', namePt: 'Tours', current: false, path: '/tours' },
  { id: 2, name: 'Transfers', namePt: 'Transfers', current: false, path: '/transfers' },
  { id: 3, name: 'About us', namePt: 'Sobre nós', current: false, path: '/about-us' },
  { id: 4, name: 'Contacts', namePt: 'Contactos', current: false, path: '/contacts' },
]

const userNavigation = [
  { name: 'Your Profile', href: '#' },
  { name: 'Settings', href: '#' },
  { name: 'Sign out', href: '#' },
]

const navigationLngs = [
  {
    id: 0,
    lng: 'en',
    name: 'En'
  },
  {
    id: 1,
    lng: 'pt-PT',
    name: 'Pt'
  }
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const Navbar = ({className, isContained = true, menuLocation='primary'}) => {

  const { t, i18n } = useTranslation()

  let menu = useRef(null)
  let menuItems = useRef(null)

  useEffect(() => {
    const nav = menu.current
    const navBounds = nav.getBoundingClientRect()
    const navItems = menuItems.current
    const tl = gsap.timeline()

    console.log(navBounds)
    nav.style.setProperty('--menu-height', `${navBounds.height}px`)

    if (menuLocation === 'banner') {
      window.addEventListener('scroll', () => {

        if (window.scrollY > navBounds.height) {
          nav.classList.add('is--scrolling')
        } else {
          nav.classList.remove('is--scrolling')
        }
      })
    }
  }, [])

  return (
    <Disclosure as="div" ref={menu} className={`menu menu-${menuLocation} ${isContained ? 'menu-contained' : ''} ${className ? className : ''}`}>
      {({ open }) => (
        <>
          <div className="menu-top">
            <div className="menu-container">
              <div className="menu__logo">
                <div className="flex flex-shrink-0 items-center">
                  <img
                    className="block h-10 w-auto"
                    src={require("./../assets/img/logo.png")}
                    alt="Image - Drive4You Logo"
                  />
                </div>
              </div>
              <nav ref={menuItems} className="menu__items" aria-label="Global">
                {navigation.map((item) => (
                  <NavLink
                    key={item.id}
                    className='menu__item'
                    to={item.path}
                    aria-current={item.current ? 'page' : undefined}
                  >
                    {i18n.language === 'en' ? item.name : item.namePt}
                  </NavLink>
                ))}
              </nav>
              <div className="menu__toggle">
                <div className="flex gap-x-3 ml-8">
                  <button
                    className="i18n-change i18n-ptPT"
                    onClick={() => {
                      i18n.changeLanguage('pt-PT')
                    }}>
                    <img src={require('./../assets/img/translations/ptPT.png')} alt="" />
                  </button>
                  <button
                    className="i18n-change i18n-enUK"
                    onClick={() => {
                      i18n.changeLanguage('en')
                    }}
                  >
                    <img src={require('./../assets/img/translations/enUK.png')} alt="" />
                  </button>
                </div>
                {/* Mobile menu button */}
                <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                  <span className="sr-only">Open menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="menu__cta">
                <Link to="/contacts" className="btn btn-secondary">
                  <span className="">{ t('buttons.bookYourDreamTour') }</span>
                </Link>
                <div className="flex gap-x-3 ml-8">
                  <button
                    className="i18n-change i18n-ptPT"
                    onClick={() => {
                      i18n.changeLanguage('pt-PT')
                    }}>
                    <img src={require('./../assets/img/translations/ptPT.png')} alt="" />
                  </button>
                  <button
                    className="i18n-change i18n-enUK"
                    onClick={() => {
                      i18n.changeLanguage('en')
                    }}
                  >
                    <img src={require('./../assets/img/translations/enUK.png')} alt="" />
                  </button>
                </div>
              </div>
            </div>
          </div>

          <Disclosure.Panel as="nav" className="menu-panel" aria-label="Global">
            <nav ref={menuItems} className="menu__items space-y-1 px-2 pb-3 pt-2">
              {navigation.map((item) => (
                <Disclosure.Button
                  key={item.name}
                  as="div"
                >
                  <NavLink
                    className='menu__item'
                    to={item.path}
                    aria-current={item.current ? 'page' : undefined}
                  >
                    {i18n.language === 'en' ? item.name : item.namePt}
                  </NavLink>
                </Disclosure.Button>
              ))}
            </nav>
            <div className="menu__cta">
              <Link to="/contacts" className="btn btn-secondary">
                <span className="">{ t('buttons.bookYourDreamTour') }</span>
              </Link>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}

export default Navbar
