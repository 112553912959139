import { Outlet, useLocation } from 'react-router-dom'
import { Container, Navbar } from './components/'
import './tailwind.css';
import './stylesheets/App.scss';


function App() {
  const location = useLocation().pathname

  return (
    <div className={`App`}>
      <Navbar menuLocation={ location != '/' ? 'primary' : 'banner' }></Navbar>
      <Outlet />
    </div>
  );
}

export default App;
