import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import './index.css';
import App from './App';
import { Home, Tours, ToursDetails, Transfers, TransfersDetails, AboutUs, Contacts } from './routes/';
import reportWebVitals from './reportWebVitals';
import './i18n'

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/",
        element: <Home />
      },
      {
        path: "/tours",
        element: <Tours />
      },
      {
        path: "/tours/:slug",
        element: <ToursDetails />
      },
      {
        path: "/transfers",
        element: <Transfers />
      },
      {
        path: "/transfers/:slug",
        element: <TransfersDetails />
      },
      {
        path: "/about-us",
        element: <AboutUs />
      },
      {
        path: "/contacts",
        element: <Contacts />
      }
    ]
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
