import React from 'react'

const Banner = ({as='div', className, children}) => {
  const DynamicTag = `${as}`

  return (
    <DynamicTag className={`banner pt-48 pb-20 ${className ? className : ''}`}>
      {children}
    </DynamicTag>
  )
}

export default Banner
