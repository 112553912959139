import React from 'react'
import { Container, GeneralForm, PageSection } from './../components/'

const AboutUs = () => {
  return (
    <>
      <PageSection>
        <PageSection.Eyebrow>
          Who are we?
        </PageSection.Eyebrow>
        <PageSection.Heading>
          Drive4You
        </PageSection.Heading>
        <PageSection.Description>
          <p>
            At Drive4you, we create tourist itineraries throughout Portugal, which can be differentiated and personalized taking into account the interests, age, and needs of tourists.
          </p>
          <br />
          <p>
            We offer personalized service, providing advice on choosing tours, shopping, gastronomy, cultural activities, and typical shows, as well as helping and guiding tourists in any unexpected situations they may encounter during their trip.
          </p>
          <br />
          <p>
            We understand that every trip is unique, and that's why we offer a range of vehicle options to accommodate your specific needs. Whether you're traveling for business or pleasure, our fleet has the right vehicle for you.
          </p>
          <br />
          <p>
            Don't waste time and energy, let us take care of your transfer needs, whether you're arriving at the airport, train station, or port. Our reliable and professional drivers will pick you up and take you directly to your destination, so you can sit back, relax, and enjoy the ride.
          </p>
        </PageSection.Description>
      </PageSection>
      <PageSection className="bg-sky-blue">
        <PageSection.Eyebrow>
          Contact us
        </PageSection.Eyebrow>
        <PageSection.Heading>
          Let us know what you need
        </PageSection.Heading>
        <PageSection.Description>
          In case you require assistance from a driver or wish to obtain a quote, the quickest way is to contact us by messaging us on WhatsApp. Additionally, you have the option to contact us through the form provided below.
        </PageSection.Description>
        <PageSection.Content>
          <GeneralForm className="w-full sm:mx-auto sm:w-8/12"></GeneralForm>
        </PageSection.Content>
      </PageSection>
    </>
  )
}

export default AboutUs
