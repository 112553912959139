import { React, useState } from 'react'
import emailjs from '@emailjs/browser'
import { useField, Formik, Form, Field, getIn } from 'formik'
import * as Yup from 'yup'
import PhoneInput from 'react-phone-input-2'
import DatePicker from 'react-datepicker'
import { PHONE_COUNTRIES } from '../@utils/constants'
import { toursData } from '../@utils/data'

import 'react-phone-input-2/lib/style.css'
import 'react-datepicker/dist/react-datepicker.css'
import { useTranslation } from 'react-i18next'

const BookTourForm = ({ className, tourName, tourId }) => {
  const [checkCountry, setCheckCountry] = useState('pt')
  const [formatedPhone, setFormatedPhone] = useState('')

  const { t, i18n } = useTranslation()

  let langTours = undefined

  switch (i18n.language) {
    case 'en':
      langTours = toursData.en
      break
    case 'pt-PT':
      langTours = toursData.pt
      break
  }

  const tourInfo = langTours.find((t) => t.id === tourId)
  const { costs } = tourInfo


  const phoneRegEx = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;

  const FormikDatePicker = ({name, blur}) => {
    const [field, meta, helpers] = useField(name)

    const { value } = meta
    const { setValue } = helpers

    return (
      <DatePicker
        {...field}
        selected={value || null}
        placeholderText='mm/dd/yyyy'
        onChange={(estDate) => {
          setValue(estDate)
        }}
        onBlur={blur}
      />
    )
  }

  const FormikPhoneInput = (props) => {
    const {
      className,
      id,
      field: { name, value },
      form: {
        errors, handleBlur, setFieldValue, touched,
      },
      form,
      label,
      country,
      containerClass,
      onChange,
      disabled,
    } = props;

    const [isFocused, setFocused] = useState(false);
    const isError = getIn(touched, name) && getIn(errors, name);
    const errorStyle = isError ? 'error' : '';
    const filledStyle = (isFocused || value) ? 'filled' : '';
    const disabledStyle = disabled ? 'disabled' : '';

    const handleInputBlur = (e) => {
      setFocused(false);
      handleBlur(e);
    };

    const handleInputFocus = () => setFocused(true);

    const onValueChange = (phoneNumber) => {
      setFieldValue(name, phoneNumber);

      if (onChange !== null) {
        onChange(phoneNumber);
      }
    };

    return (
      <PhoneInput
        placeholder="Enter phone number"
        inputProps={{
          id: id,
          name: name
        }}
        value={value}
        onChange={onValueChange}
        country={country}
        containerClass={containerClass}
      />
    );
  };

  const paxOptions = [
    {
      text: 'One to three',
      value: 'One to three'
    },
    {
      text: 'Four to six',
      value: 'Four to six'
    },
    {
      text: '7+',
      value: '7+'
    }
  ]

  /* const formik = <Formik
  initialValues={{
    fullName: '',
    email: '',
    phone: '',
    estDate: '',
    pax: '',
    subject: '',
    message: '',
    terms: ''
  }}
  validationSchema={Yup.object({
    fullName: Yup.string().required('Name is required.'),
    email: Yup.string().email('Email address is not valid.').required('Email is required.'),
    phone: Yup.string()
      .test('len', 'Phone number is not valid.', (value) => {
        const correspondingCountry = PHONE_COUNTRIES.find((country) => country.iso2 === checkCountry.toUpperCase())
        let valid = false

        if (correspondingCountry && formatedPhone) {
          valid = new RegExp(correspondingCountry.validation).test(formatedPhone?.replace(/ /g, ''))
        } else if (formatedPhone) {
          valid = /(([+][(]?[0-9]{1,3}[)]?)|([(]?[0-9]{4}[)]?))\s*[)]?[-\s.]?[(]?[0-9]{1,3}[)]?([-\s.]?[0-9]{3})([-\s.]?[0-9]{3,4})/.test(formatedPhone?.replace(/ /g, ''))
        } else if (formatedPhone === '') {
          valid = true
        }

        return valid
    }),
    estDate: Yup.date().min(new Date(), 'Est. date should not be before today.').required('Est. date is required').nullable(),
    pax: Yup.string().required('Pax is required.'),
    subject: Yup.string().required('Subject is required.'),
    message: Yup.string().required('Message is required.')
  })}
  onSubmit={(values) => {
    const mail_configs = {
      from_name: values.fullName,
      reply_to: values.email,
      subject: `[New Tour Lead] ${tourName} - ${values.fullName} (${values.estDate})`,
      message: values.message,
      phone_number: values.phone ? values.phone : ''
    }

    emailjs.send('service_drive4you', 'template_rht7lmq', mail_configs, 'xUp0VSUkSBd0zP3Pv')
      .then((response) => {
        console.log(response)
      }, (err) => {
        console.log(err);
      })

    console.log(mail_configs)
  }}
>
</Formik> */

  /* const sendEmail = (e) => {
    e.preventDefault()

    const mail_configs = {
      from_name: formik.values.fullName,
      reply_to: formik.values.email,
      subject: `formik.values.subject`,
      message: formik.values.message
      (formik.values.phone && { phone_number: formik.value.phone })
    }

    emailjs.send('service_drive4you', 'template_rht7lmq', mail_configs, 'xUp0VSUkSBd0zP3Pv')
      .then((response) => {
        console.log(response)
      }, (err) => {
        console.log(err);
      })
  }*/

  return (
    <Formik
      initialValues={{
        fullName: '',
        email: '',
        phone: '',
        estDate: '',
        pax: '',
        subject: '',
        message: '',
        terms: ''
      }}
      onSubmit={(values) => {
        console.log(values);
        console.log(tourName.replace('/', '\/'))
        const mail_configs = {
          from_name: values.fullName,
          reply_to: values.email,
          subject: `[New Tour Lead] ${tourName.replace('/', ' e ')} - ${values.fullName}`,
          message: values.message,
          phone_number: values.phone,
          pax: values.pax,
          est_date: values.estDate.toLocaleDateString('pt-PT'),
          tour_title: `Tour - ${tourName}`
        }

        emailjs.send('service_driver', 'template_6lo0cby', mail_configs, 'xUp0VSUkSBd0zP3Pv')
          .then((response) => {
            console.log(response)
          }, (err) => {
            console.log(err);
          })

        console.log(mail_configs)
      }}
    >
      {(props) => (
        <Form className={`form form--bordered${ className ? ` ${className} ` : ' ' }isolate w-full flex`}>
          <div className="form-content shadow-sm">
            <div className="form-group">
              <div className="form__element w-full">
                <label htmlFor="fullName" className="form__label">
                  { t('forms.fullName') }
                  {props.touched.fullName && props.errors.fullName ? (<span className="form__error">{props.errors.fullName}</span>) : ''}
                </label>
                <input
                  className="form__input"
                  type="text"
                  name="fullName"
                  id="fullName"
                  placeholder="Jane Smith"
                  value={props.values.fullName}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                />
                <span className="form__focus"></span>
              </div>
            </div>
            <div className='form-group'>
              <div className="form__element w-full">
                <label htmlFor="email" className="form__label">
                  { t('forms.email') }
                  {props.touched.email && props.errors.email ? (<span className="form__error">{props.errors.email}</span>) : ''}
                </label>
                <input
                  type="text"
                  name="email"
                  id="email"
                  className="form__input"
                  placeholder="you@example.com"
                  value={props.values.email}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                />
                <span className="form__focus"></span>
              </div>
            </div>
            <div className="form-group">
              <div className="form__element h-[66px] w-full">
                <label htmlFor="phone" className="form__label">
                  { t('forms.phone') }
                  {props.touched.phone && props.errors.phone ? (<span className="form__error">{props.errors.phone}</span>) : ''}
                </label>
                {/* <input
                  type="text"
                  name="phone"
                  id="phone"
                  className="form__input"
                  placeholder="+351934012734"
                  value={props.values.phone}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                /> */}
                <FormikPhoneInput
                  field={{
                    name: 'phone',
                    value: props.values.phone
                  }}
                  id='phone'
                  type='text'
                  form={props}
                  containerClass="form__input"
                  country={'pt'}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                />
                <span className="form__focus"></span>
              </div>
            </div>
            <div className='form-group'>
              <div className="form__element w-full">
                <label htmlFor="estDate" className="form__label">
                  { t('forms.estDate') }
                  {props.touched.estDate && props.errors.estDate ? (<span className="form__error">{props.errors.estDate}</span>) : ''}
                </label>
                <FormikDatePicker name="estDate" blur={props.handleBlur} className='form-input'/>

                <span className="form__focus"></span>
              </div>
            </div>
            <div className="form-group">
              <div className="form__element w-full">
                <label htmlFor="pax" className="form__label">
                  { t('forms.pax') }
                  {props.touched.pax && props.errors.pax ? (<span className="form__error">{props.errors.pax}</span>) : ''}
                </label>
                <Field as='select' name="pax" id="pax" className='form__input' value={props.values.pax} onChange={props.handleChange} onBlur={props.handleBlur} placeholder='Choose how many'>
                  <option value="" disabled defaultValue>Select how many</option>
                  <option value={`One to three (${costs.oneToThree})`}>One to three</option>
                  <option value={`Four to six (${costs.fourToSix})`}>Four to six</option>
                  <option value={`7+ (${costs.sevenPlus})`}>7+</option>
                </Field>
                <span className="form__focus"></span>
              </div>
            </div>
            <div className="form-group">
              <div className="form__element w-full">
                <label htmlFor="message" className="form__label">
                  { t('forms.message') }
                  {props.touched.message && props.errors.message ? (<span className="form__error">{props.errors.message}</span>) : ''}
                </label>
                <textarea
                  name="message"
                  className='form__input'
                  id="message"
                  rows="8"
                  placeholder="Your message here..."
                  value={props.values.message}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                ></textarea>
                <span className="form__focus"></span>
              </div>
            </div>
          </div>
          <div className="form-consent">
            <p>{ t('toursDetails.formDisclaimer') }</p>
          </div>
          <div className="form-actions">
            <button className="btn btn-secondary" type="submit">Book this tour!</button>
          </div>
        </Form>
      )}
    </Formik>

  )
}

export default BookTourForm
