import i18n from 'i18next'
import { initReactI18next } from "react-i18next"
import en from './locales/en/en.json'
import ptPT from './locales/pt/pt-Pt.json'

const resources = {
  'pt-PT' : ptPT,
  'en' : en
}

i18n
  .use(initReactI18next)
  .init({
    fallbackLng : 'en',
    resources,
    lng : 'en',
    interpolation : {
      escapeValue: false,
    }
  })

export default i18n
