import React from 'react'
import { useParams } from 'react-router'
import { transfersData } from '../@utils/data'
import { BookTransferForm, PageSection } from '../components'
import { useTranslation } from 'react-i18next'

const TransfersDetails = () => {
  const { slug } = useParams()
  const { t, i18n } = useTranslation()

  let langTransfers = undefined

  switch (i18n.language) {
    case 'en':
      langTransfers = transfersData.en
      break
    case 'pt-PT':
      langTransfers = transfersData.pt
      break
  }

  let transfer = langTransfers.find((t) => t.slug === slug)
  console.log(transfer)

  return (
    <>
      <PageSection>
        <PageSection.Content>
          <div className='tours-details'>
            <div className="tours-details__content">
              <div className="tours-details__thumbnail">
                <img src={require(`./../assets/img/${transfer.thumbnail}`)} alt={transfer.thumbnailAlt} />
              </div>
              <div className="tours-details__title">
                <h1>{transfer.title}</h1>
              </div>
              <div className="tours-details__sections">
                <section className="tours-details__section">
                  <div className="tours-details__title">
                    <h2>{ t('transfersDetails.passengerRate') }</h2>
                  </div>
                  <div className="tours-details__content">
                    <p>{ t('transfersDetails.startingFrom', { startingFrom: transfer.startingFrom }) }</p>
                  </div>
                </section>
                {
                  false ?
                    (
                      <section className="tours-details__section">
                        <div className="tours-details__title">
                          <h2>Duration</h2>
                        </div>
                        <div className="tours-details__content">
                          <p>{transfer.duration}</p>
                        </div>
                      </section>
                    ) :
                    ''
                }
                <section className="tours-details__section">
                  <div className="tours-details__title">
                    <h2>{ t('toursDetails.about') }</h2>
                  </div>
                  <div className="tours-details__content">
                    <p>{transfer.description}</p>
                  </div>
                </section>
              </div>
            </div>
            <div className="tours-details__form">
              <div className="tours-details__title">
                <h2>Book your transfer now!</h2>
              </div>
              <BookTransferForm transferName={transfer.title}></BookTransferForm>
            </div>
          </div>
        </PageSection.Content>
      </PageSection>
    </>
  )
}

export default TransfersDetails
