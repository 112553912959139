import React from 'react'
import { Container, GeneralForm, PageSection } from './../components/'
import { useTranslation } from 'react-i18next'

const Contacts = () => {
  const { t } = useTranslation()

  return (
    <>
      <PageSection>
        <PageSection.Eyebrow>
          {t('contactUs.eyebrow')}
        </PageSection.Eyebrow>
        <PageSection.Heading>
          {t('contactUs.title')}
        </PageSection.Heading>
        <PageSection.Description>
          {t('contactUs.description')}
        </PageSection.Description>
        <PageSection.Content>
          <GeneralForm className="w-full sm:mx-auto sm:w-8/12"></GeneralForm>
        </PageSection.Content>
      </PageSection>
    </>
  )
}

export default Contacts
