import { Fragment, useState, useEffect } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { useTranslation } from 'react-i18next'

const Item = (props) => {
  console.log(props)

  return (
    <Listbox.Option
      {...props}
      className='select-menu__item'
    >
      {props.children}
    </Listbox.Option>
  )
}

const SelectMenu = ({label, children, value, defaultValue}) => {
  const { t, i18n } = useTranslation()

  const [selected, setSelected] = useState(value)

  useEffect(() => {
    const defValue = selected.find(language => {
      return language.lng === defaultValue
    })

    setSelected(defValue)

  }, [])

  return (
    <Listbox className='select-menu' value={selected} onChange={setSelected}>
      {({ open }) => (
        <>
          <Listbox.Label className="select-menu__label ph">{ label }</Listbox.Label>
          <div className="select-menu__wrapper">
            <Listbox.Button className="select-menu__display relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
              <span className="block truncate">{selected.name}</span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="select-menu__items">
                {children}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  )
}

SelectMenu.Item = Item

export default SelectMenu


{/*people.map((option) => (
  <Listbox.Option
    key={option.id}
    className='select-menu__item'
    value={option}
  >
    {({ selected, active }) => (
      <>
        <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
          {option.name}
        </span>

        {selected ? (
          <span
            className={classNames(
              active ? 'text-white' : 'text-indigo-600',
              'absolute inset-y-0 right-0 flex items-center pr-4'
            )}
          >
            <CheckIcon className="h-5 w-5" aria-hidden="true" />
          </span>
        ) : null}
      </>
    )}
  </Listbox.Option>
))*/}
