import React from 'react'

const Eyebrow = ({ children }) => (<h2 className="page-section__eyebrow">{children}</h2>)
const Heading = ({ children }) => (<h3 className="page-section__heading">{children}</h3>)
const Description = ({ children }) => (<div className="page-section__description">{children}</div>)
const Content = ({ children, className }) => (<div className={`page-section__content${ className ? ` ${className}` : '' }`}>{children}</div>)
const Actions = ({ children }) => (<div className="page-section__actions">{children}</div>)

function PageSection({ children, className }) {
  return (
    <section className={`page-section${ className ? ` ${className}` : '' }`}>
      <div className="page-section__container">
        { children }
      </div>
    </section>
  )
}

PageSection.Eyebrow = Eyebrow
PageSection.Heading = Heading
PageSection.Description = Description
PageSection.Content = Content
PageSection.Actions = Actions

export default PageSection
